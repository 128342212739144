import { Axios } from "../hooks/useAxiosInstance";

export const Profile = (function () {

    // const { api_token } = useSelector(state => state.profile);

    const apis = () => ({
        post: async (data) => await postRecord(data),
        postForgetPassword: async (data) => await postForget(data),
        updatePassword: async (data, api_token) => await updatePassword(data, api_token)
    })

    async function postRecord(data) {
        const url = "login";
        const option = {
            headers: {
                'token': '979d879f-4393-42ff-b3ed-3689a3b058a2',
                'Content-Type': 'application/json'
            }
        }

        return await Axios.post(url, data, option);
    }


    async function postForget(data) {
        const url = "forgot-password";
        const option = {
            headers: {
                'token': '979d879f-4393-42ff-b3ed-3689a3b058a2',
                'Content-Type': 'application/json'
            }
        }

        return await Axios.post(url, data, option);
    }

    async function updatePassword(data, api_token) {
        const url = "change-password";
        const option = {
            headers: {
                'Authorization': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.post(url, data, option);
    }

    return apis();

})()
