import React, { useCallback, useEffect, useState } from "react";

/**Import Styles and Images */
import styles from "./index.module.scss"

import TableLayout from "../../components/TableLayout";
import { useEarningHeader } from "../../schemas/tableHeaderSchema";
import { Earning as EarningApi } from "../../Api/Earning";

import { useSelector, useDispatch } from "react-redux";
import { resetValue } from "../../features/SearchReducer";
import { useQuery } from "react-query";
import ContentContainer from "../../components/ContentContainer";
import { Toast } from "../../hooks/useToast";


export default function Earning() {
    const PAGE_SIZE = 8;
    const { api_token } = useSelector(state => state.profile.data)
    const { search } = useSelector(state => state.search);
    const [pageNo, setPageNo] = useState(1)


    const { data, isLoading, refetch } = useQuery({
        queryKey: ['earnings', pageNo],
        queryFn: () => EarningApi.get(api_token, pageNo, PAGE_SIZE),
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: false,
        onError: (err) => Toast(err.message, 'error', false)
    })

    const header = useEarningHeader();

    const dispatch = useDispatch();



    useEffect(() => {
        return () => dispatch(resetValue())
    }, [])


    const handlePageChange = useCallback((value) => {
        setPageNo(value)
    }, [])
    console.log(data)
    return (
        <ContentContainer>
            <div className={styles.Earning}>
                <TableLayout
                    isLoading={isLoading}
                    data={data?.data ?? []}
                    headers={header}
                    totalRecord={data?.links?.total_records ?? 0}
                    pageNo={pageNo}
                    handlePageChange={handlePageChange}
                    pageSize={PAGE_SIZE}
                />
            </div>
        </ContentContainer>
    )
}