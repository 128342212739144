import { Axios } from "../hooks/useAxiosInstance";

export const Earning = (function () {

    // const { api_token } = useSelector(state => state.profile);

    const apis = () => ({
        get: async (api_token) => await getRecord(api_token),
    })


    async function getRecord(api_token) {
        const url = `earnings`;
        const option = {
            headers: {
                'Authorization': api_token,
            }
        }

        return await Axios.get(url, option);
    }

    return apis();

})()
