import React from "react";

import "./TableLayout.css";

import { Table } from "antd";

import { HiOutlineFolder } from "react-icons/hi"

export default function TableLayout({ headers, data, isLoading, totalRecord, pageNo, handlePageChange, pageSize = 8 }) {

    const emptyContainer = <div className={'ant-empty-description'}>
        <span className='icon'><HiOutlineFolder /></span>
        <h1>No Data Found</h1>
    </div>

    return (

        <Table
            tableLayout="auto"
            loading={isLoading}
            rowKey="slug"
            locale={{ emptyText: emptyContainer }}
            columns={headers}
            dataSource={data}
            rowsPerPageOptions={[]}
            pagination={{
                pageSize: pageSize,
                position: ["bottomCenter"],
                className: "pagination",
                hideOnSinglePage: true,
                pageSizeOptions: [],
                total: (totalRecord ?? data?.length),
                current: pageNo,
                onChange: (page) => handlePageChange(page)
            }}
            className={"table"} rowClassName={"row"}
        />

    )
}