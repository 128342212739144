import React, { useCallback, useState } from "react";

/**Import Styles and Assets */
import styles from "./ContentLayout.module.scss";

export default function ContentLayout({ children }) {
    return (
        <div className={styles.contentLayout}>
            {children}
        </div>
    )
}