import { Axios } from "../hooks/useAxiosInstance";

export const Notification = (function () {

    // const { api_token } = useSelector(state => state.profile);

    const apis = () => ({
        post: async (data, api_token) => await postRecord(data, api_token),
    })


    async function postRecord(data, api_token) {
        const url = `notification`;
        const option = {
            headers: {
                'Authorization': api_token,
            }
        }

        return await Axios.post(url, data, option);
    }

    return apis();

})()
