import axios from "axios";

export const Axios = (function () {

    const getServicePayload = (apiCoreServiceInstance) => ({
        get: (url, options = {}) => apiCoreServiceInstance.get(url, { ...options }),
        post: (url, data, options = {}) =>
            apiCoreServiceInstance.post(url, data, { ...options }),
        put: (url, data, options = {}) =>
            apiCoreServiceInstance.put(url, data, { ...options }),
        patch: (url, data, options = {}) =>
            apiCoreServiceInstance.patch(url, data, { ...options }),
        delete: (url, options = {}) =>
            apiCoreServiceInstance.delete(url, { ...options }),
    });

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_DOMAIN + "api/admin/",
        headers: {
            'Content-Type': 'application/json',

        }

    })



    const apiCoreServiceRequestInterceptor = (axiosConfig) => {
        return axiosConfig;
    };
    const apiCoreServiceRequestErrorInterceptor = (error) => {
        Promise.reject(error);
    }



    // Response interceptors config
    const apiCoreServiceResponseInterceptor = (response) => {
        return response.data;

    }
    const apiCoreServiceResponseErrorInterceptor = (error) => {
        if (error.code === "ERR_NETWORK") {
            return Promise.reject(error);
        }
        if (error.response.data.code === 401) {
            localStorage.removeItem(process.env.REACT_APP_WEB_STORAGE_TOKEN);
            window.location = `/`
        }
        return Promise.reject(error.response.data);

    };

    axiosInstance.interceptors.request.use(
        apiCoreServiceRequestInterceptor,
        apiCoreServiceRequestErrorInterceptor,
    );

    axiosInstance.interceptors.response.use(
        apiCoreServiceResponseInterceptor,
        apiCoreServiceResponseErrorInterceptor,
    );


    return getServicePayload(axiosInstance);


})()