import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom"

/**Import Assets */
import styles from "./ProtectedLayout.module.scss";

/**Import Custom Component */
import Navbar from "./Navbar";

import dashboard from "./images/dashboard.png";
import user from "./images/user.png";
import transaction from "./images/transaction.png";
import earning from "./images/earning.png";
import chat from "./images/chat.png";
import password from "./images/password.png";
import terms from "./images/terms.png";
import privacy from "./images/privacy.png";
import notification from "./images/notification.png";
import logout from "./images/logout.png";


import { updateKey } from "../../features/DefaultReducer";
import { resetState as profileReset } from "../../features/ProfileReducer";

import { resetValue as searchReset } from "../../features/SearchReducer";

import { confirmAlert } from 'react-confirm-alert';
import { useDispatch } from "react-redux";
import { Menu } from 'antd';
import { BiLogOut } from "react-icons/bi"


export default function ProtectedLayout({ selectedRoute, isSearchBar, children }) {

    const dispatch = useDispatch();
    const [collapsed, setCollapsed] = useState(false)

    const items = [
        {
            icon: dashboard,
            label: "Dashboard",
        },
        {
            icon: user,
            label: "User Management",
        },
        {
            icon: transaction,
            label: "Transaction",
        },
        {
            icon: earning,
            label: "Earnings",
        },
        {
            icon: chat,
            label: "Chat",
        },
        {
            icon: password,
            label: "Change Password",
        },
        {
            icon: terms,
            label: "Terms And Conditions",
        },
        {
            icon: privacy,
            label: "Privacy Policy",
        },
        {
            icon: notification,
            label: "Notification",
        },

    ]

    function handleLogout() {
        dispatch(updateKey({ isLoggedIn: false }));
        dispatch(profileReset());
        dispatch(searchReset())
        localStorage.removeItem(process.env.REACT_APP_WEB_STORAGE_TOKEN)

    }

    function submit(title, message, cb, rejectCB = () => { }) {
        confirmAlert({
            title,
            message,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => cb()
                },
                {
                    label: 'No',
                    onClick: () => rejectCB()
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
        });
    }


    const handleToggle = () => {
        setCollapsed(!collapsed)
    }

    const handleMenuClose = () => {
        setCollapsed(false)
    }
    return (
        <div className={styles.protectedLayout}>
            <div className={collapsed ? styles.background : ''} onClick={handleMenuClose}></div>
            <div
                className={`${styles.menu} ${collapsed ? `${styles.collapsed} visible` : styles.notCollapsed}`}
                id="sider"
            >

                <Link className={`${styles.logo} ${!collapsed ? styles.notCollapsed : ''}`} to={`${process.env.REACT_APP_DOMAIN_DIR}/dashboard`}>
                    <img src={collapsed ? '/logo.png' : '/logo_small.png'} alt="Logo Image" className={collapsed ? styles.imageCollapsed : styles.imageNotCollapsed} />
                </Link>

                <div className={`${styles.drawer} ${collapsed ? styles.collapsed : ''}`}>

                    <span onClick={handleToggle}>
                        <BiLogOut />
                    </span>
                </div>

                {items.map(({ icon, label }, index) => {
                    return <Link to={`${process.env.REACT_APP_DOMAIN_DIR}/${label.toLowerCase().replace(/[^A-Z0-9]/ig, "-")}`} className={`${styles.item} ${index == selectedRoute ? styles.active : ""}`} key={index}>
                        <img src={icon} alt={`Icon${index}`} width={20} height={20} />
                        {collapsed ? <p>{label}</p> : ''}
                    </Link>
                })}

                <Link className={styles.item} onClick={() => submit("Confirm Logout", "Are you sure you want to logout?", () => handleLogout())} >
                    <img src={logout} alt="Icon1" width={20} height={20} />
                    {collapsed ? <p>Logout</p> : ''}
                </Link>

            </div>
            <div className={styles.routeContent}>
                <Navbar isSearchBar={false} title={items[selectedRoute].label} handleToggle={handleToggle} />
                <div className={styles.content}>
                    {children}
                </div>
            </div>
        </div >
    )
}