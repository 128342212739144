import React, { useState } from "react";

import styles from "./index.module.scss"
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Toast } from "../../hooks/useToast";
import { Transaction as TransactionAPI } from "../../Api/Transaction"
import { useTransactionHeader, useUserTransactionHeader } from "../../schemas/tableHeaderSchema";
import TableLayout from "../../components/TableLayout";
import { IoMdArrowRoundBack } from "react-icons/io";
import TableDefaultLayout from "../../components/TableLayout/DefaultLayout";

export default function Transaction() {
    const INITIAL_STATE = {
        isTransactionListing: false,
        data: []
    }
    const PAGE_SIZE = 8;
    const { api_token } = useSelector(state => state.profile.data)
    const [pageNo, setPageNo] = useState(1)
    const [page, setPage] = useState(INITIAL_STATE)
    const TRANSACTION_HEADER = useTransactionHeader(setPage);
    const USER_TRANSACTION_HEADER = useUserTransactionHeader()


    const { data, isLoading, refetch } = useQuery({
        queryKey: ['transaction', pageNo],
        queryFn: () => TransactionAPI.get(api_token, pageNo, PAGE_SIZE),
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: false,
        onError: (err) => Toast(err.message, 'error', false)
    })

    return (
        <div className={styles.Transaction}>
            {!page.isTransactionListing ?
                <TableLayout
                    isLoading={isLoading}
                    data={data?.data ?? []}
                    headers={TRANSACTION_HEADER}
                    totalRecord={data?.links?.total_records ?? 0}
                    pageNo={pageNo}
                    handlePageChange={setPageNo}
                    pageSize={PAGE_SIZE}
                />
                :
                <>
                    <div className={styles.headerContainer}>
                        <div
                            className={styles.backButton}
                            onClick={() => setPage(INITIAL_STATE)}
                        >
                            <IoMdArrowRoundBack />
                            <h1 className={styles.backButton} >Back</h1>
                        </div>
                    </div>
                    <TableDefaultLayout
                        isLoading={false}
                        data={page.data ?? []}
                        headers={USER_TRANSACTION_HEADER}
                        pageSize={PAGE_SIZE}
                    />
                </>}
        </div>
    )
}