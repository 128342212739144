
import { confirmAlert } from 'react-confirm-alert';
import { ReactComponent as Bin } from "./images/bin.svg"
import moment from "moment"


export function submit(title, message, cb, rejectCB = () => { }) {
    confirmAlert({
        title,
        message,
        buttons: [
            {
                label: 'Yes',
                onClick: () => cb()
            },
            {
                label: 'No',
                onClick: () => rejectCB()
            }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
        keyCodeForClose: [8, 32],
    });
}


export function useUserManagementHeader(pageNo, handleUpdateStatus, handleDeleteUser) {

    return [
        {
            title: 'Username',
            render: (record, val) => {
                return <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                    <img src={val.image_url} alt="User Image" style={{ width: "30px", height: "30px", borderRadius: "100%" }} />
                    <p style={{ margin: "0 15px" }}>{val.name}</p>

                </div>
            }
        },
        {
            title: 'Email Address',
            dataIndex: 'email',
            align: "center",
        },
        {
            title: 'Phone Number',
            dataIndex: 'mobile_no',
            align: "center",
            render: (mobile_no) => {
                return <p style={{ margin: 0, textAlign: "center" }}>{mobile_no || '-'}</p>
            }
        },
        {
            title: 'Status',
            width: 170,
            key: 'action',
            align: "center",
            render: (record) => {
                const status = record.is_blocked ? 'unblock' : 'block';
                return <div
                    onClick={() =>
                        submit(
                            "Change Status", `Are you sure you want to ${status} this user?`,
                            () => handleUpdateStatus({ slug: record.slug, page: pageNo })

                        )
                    }
                    style={{ width: "100%", cursor: "pointer", background: "black", padding: "3px 15px", display: "flex", alignItems: "center" }}>
                    <a
                        style={{ width: "100%", color: "#7BE35C", marginRight: "30px", color: (record.is_blocked == 0) ? "#7BE35C" : "#CB0C51" }}>{!record.is_blocked ? "Unblock" : "Block"}</a>
                </div>
            },
        },
        // {
        //     width: 170,
        //     key: 'action',
        //     align: "center",
        //     render: (record) => {
        //         return <div
        //             onClick={() =>
        //                 submit(
        //                     "Confirm Deletion", `Are you sure you want to delete this user?`,
        //                     () => handleDeleteUser({ slug: record.slug, page: pageNo })

        //                 )
        //             }
        //             style={{
        //                 display: "flex",
        //                 flexDirection: "row",
        //                 color: "#CB0C51",
        //                 alignItems: "center",
        //                 justifyContent: "center",
        //                 cursor: "pointer",
        //                 fontSize: "15px"
        //             }}>
        //             <Bin style={{ marginRight: "5px" }} />Delete
        //         </div>
        //     },
        // },
    ];
}

export function useEarningHeader() {

    return [
        {
            title: 'Username',
            render: (record, val) => {
                return <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                    <img src={val.image_url} alt="User Image" style={{ width: "30px", height: "30px", borderRadius: "100%" }} />
                    <p style={{ margin: "0 15px" }}>{val.name}</p>

                </div>
            }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            align: "center",
            render: (amount) => {
                return <p style={{ margin: 0, textAlign: "center" }}>{`$${amount}` || '$0'}</p>
            }
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            align: "center",
            render: (date) => {
                return <p style={{ margin: 0, textAlign: "center" }}>{date || '-'}</p>
            }
        },
    ];
}

export function useTransactionHeader(setListing) {

    return [
        {
            title: 'Username',
            render: (record, val) => {
                return <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                    <img src={val.image_url} alt="User Image" style={{ width: "30px", height: "30px", borderRadius: "100%" }} />
                    <p style={{ margin: "0 15px" }}>{val.name}</p>

                </div>
            }
        },
        {
            title: 'Email Address',
            dataIndex: 'email',
            align: "center",
        },
        {
            width: 170,
            key: 'action',
            align: "center",
            render: (record) => {
                const status = record.is_blocked ? 'unblock' : 'block';
                return <a
                    onClick={() => {
                        setListing(prev => {
                            return {
                                ...prev,
                                isTransactionListing: true,
                                data: record.transactions
                            }
                        })
                    }}
                >
                    View Details
                </ a>
            },
        },

    ];
}
export function useUserTransactionHeader(setListing) {

    return [
        {
            title: 'Account Type',
            dataIndex: 'account_type',
            align: "center"
        },
        {
            title: 'Bank Name',
            dataIndex: 'bank_name',
            align: "center",
            render: (text) => {
                return <p style={{ margin: 0, textAlign: "center" }}>{text || '-'}</p>
            }
        },
        {
            title: 'Bank Account No',
            dataIndex: 'bank_accountNo',
            align: "center",
            render: (text) => {
                return <p style={{ margin: 0, textAlign: "center" }}>{text || '-'}</p>
            }
        },
        {
            title: 'IBAN No.',
            dataIndex: 'iban',
            align: "center",
            render: (text) => {
                return <p style={{ margin: 0, textAlign: "center" }}>{text || '-'}</p>
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            align: "center",
            render: (text) => {
                return <p style={{ margin: 0, textAlign: "center", textTransform: "uppercase" }}>{text}</p>
            }
        },
    ];
}


