import { Axios } from "../hooks/useAxiosInstance";

export const UserManagement = (function () {

    // const { api_token } = useSelector(state => state.profile);

    const apis = () => ({
        get: async (api_token, page = 1, limit = 8) => await fetchRecord(api_token, page, limit),
        update: async (user_slug, api_token) => await updateRecord(user_slug, api_token),
        delete: async (user_slug, api_token) => await deleteRecord(user_slug, api_token),
    })

    async function fetchRecord(api_token, page, limit) {
        const url = `users?page=${page}&limit=${limit}`;
        const option = {
            headers: {
                'Authorization': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.get(url, option);
    }

    async function updateRecord(user_slug, api_token) {
        const url = "users/" + user_slug;
        const option = {
            headers: {
                'Authorization': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.patch(url, {}, option);
    }

    async function deleteRecord(user_slug, api_token) {
        const url = "users/" + user_slug;
        const option = {
            headers: {
                'Authorization': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.delete(url, option);
    }


    return apis();

})()
