import React, { useMemo, useState } from "react";

import styles from "./index.module.scss";

import { Toast } from "../../hooks/useToast";
import ContentContainer from "../../components/ContentContainer";
import { Notification as NotificationAPI } from "../../Api/Notification";

import { Input } from "antd";
import { useSelector } from "react-redux";

export default function Notification() {
    const user = useSelector(state => state.profile.data);
    const INITIAL_STATE = useMemo(() => {
        return {
            title: "",
            message: "",
        }
    }, [])
    const [data, setData] = useState(INITIAL_STATE);
    const [isSubmitted, setIsSubmitted] = useState(false);


    function handleChange(e) {
        const { name, value } = e.target;
        setData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    async function handleSubmit() {
        console.log(data)
        if (isSubmitted) return;
        if (!data.title) return Toast("Title is required", 'error', false)
        if (!data.message) return Toast("Message is required", 'error', false)

        try {
            setIsSubmitted(true);
            Toast("Sending Notification", 'loading', true);
            await NotificationAPI.post(data, user.api_token)
            Toast("Notification send successfully", 'success', false);
            setData(INITIAL_STATE)


        } catch (err) {
            Toast(err.message, 'error', false)
        }
        finally {
            setIsSubmitted(false)
        }


    }
    return (
        <ContentContainer>
            <div className={styles.Notification}>
                {/* <h1 className={styles.contentTitle}>Change Password</h1> */}
                <div className={styles.content}>
                    <div className={styles.row}>
                        <Input
                            placeholder="Title"
                            autoComplete="off"
                            name="title"
                            value={data.title}
                            onChange={handleChange}
                        />
                    </div>
                    <div className={styles.row}>
                        <Input
                            placeholder="Message"
                            autoComplete="off"
                            name="message"
                            value={data.message}
                            onChange={handleChange}
                        />
                    </div>
                    <button className={styles.submit} onClick={handleSubmit}>
                        Send
                    </button>
                </div>
            </div>
        </ContentContainer>
    )
}